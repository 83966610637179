import React, { useEffect, useState } from 'react'
import { Section } from '../components'
import { Row, Col, Button, Form, Alert } from 'react-bootstrap'
import contact from '../data/contact.json'
import emailjs from '@emailjs/browser'

export function Contact(){
  const [values, setValues] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  const handleSubmit = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    emailjs.send('service_4ijxjth', 'template_42kvba6', values, 'WY-BD9YE6uauB6MFq')
      .then(response => {
        console.log('SUCCESS!', response);
        setValues({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
        setStatus('SUCCESS');
      }, (error:Error) => {
        console.log('FAILED...', error);
      });
  }

  useEffect(() => {
    if(status === 'SUCCESS') {
      setTimeout(() => {
        setStatus('');
      }, 3000);
    }
  }, [status]);

  const handleChange = (e:any) => {
    setValues(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }

  return (
    <Section id="contact" title="Get In Touch">
      <Row>
        <Col md={4}>
          <div className="contact-info">
            <h3>{contact.title}</h3>
            <div dangerouslySetInnerHTML={ { __html: contact.subTitle } } />
          </div>
        </Col>
        <Col md={8}>
          {status && renderAlert()}
          <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="inputName">
                <Form.Control type="text" name='name' value={values.name} onChange={handleChange} className="kd-form-control" placeholder="Your name" />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="inputEmail">
                <Form.Control type="email" name='email' value={values.email} onChange={handleChange} className="kd-form-control" placeholder="Email address" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="inputSubject">
                <Form.Control type="text" name='subject' value={values.subject} onChange={handleChange} className="kd-form-control" placeholder="Subject" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group controlId="inputMessage">
                <Form.Control as="textarea" name='message' value={values.message} onChange={handleChange} rows={5} className="kd-form-control" placeholder="Message" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button type='submit' variant="kd">Send Message</Button>
            </Col>
          </Row>
          </Form>
        </Col>
      </Row>
    </Section>
  )
};


const renderAlert = () => {
  return (
    <Alert variant="success">
      <Alert.Heading>Your message submitted successfully.</Alert.Heading>
    </Alert>
  );
};

export default Contact;

